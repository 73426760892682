export default {
  store (monitorId, signl4_webhook) {
    return axios.post(`/monitors/${monitorId}/notifications/signl4`, {
      signl4_webhook
    })
  },

  generalStore (signl4_webhook) {
    return axios.post(`/monitors/general/notifications/signl4`, {
      signl4_webhook
    })
  }
}
